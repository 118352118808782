const modal = document.querySelector('.modal');
const modalClose = document.querySelector('.modal__close');

function closeModal(e) {
    if(e.target.closest('.consent')) return;
    if(e.target.closest('.modal__container') && !e.target.closest('.modal__close')) return;
    modal.classList.add('hidden');
}

modalClose?.addEventListener('click', closeModal);
modal?.addEventListener('click', closeModal);